.preloader__wrapper {
  overflow: hidden;
  background: #fff;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 9999;
  display: flex;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
}

.preloader {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.preloader__line {
  height: 116px;
  width: 82%;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

.block1,
.block2 {
  display: flex;
  width: calc(50% - 58px);
  height: 116px;
  position: absolute;
  background: #fff;
  z-index: 0;
}

.textPre {
  display: flex;
  justify-content: center;
  position: absolute;
  width: 100%;
  height: 100%;
  color: #000;
  font-size: 7em;
  align-items: center;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 30px;
  font-family: sans-serif;
}

.block1 {
  position: absolute;
  z-index: 2;
  justify-content: flex-end;
  left: 0;
}

.block2 {
  position: absolute;
  z-index: 0;
  justify-content: flex-start;
  right: 0;
}

.block1 svg {
  position: absolute;
  right: -116px;
  justify-content: flex-start;
  z-index: 5;
}

.block2 svg {
  left: -116px;
  position: absolute;
  justify-content: flex-end;
  z-index: 5;
  background: white;
}

.anlBlock {
  animation: leftMoveBlock 1s linear infinite alternate;
}

.anRBlock {
  animation: rightMoveBlock 1s linear infinite alternate;
}

.anl {
  animation: leftMovePre 1s linear infinite alternate;
}

.anR {
  animation: rightMovePre 1s linear infinite alternate;
}

.circkle__loader div {
  position: absolute;
  animation: circkleLoader 1s linear infinite;
  width: 160px;
  height: 160px;
  top: 20px;
  left: 20px;
  border-radius: 50%;
  box-shadow: 0 4px 0 0 #3d40d5;
  transform-origin: 80px 82px;
}

.circkle__loader-wrapper {
  width: 200px;
  height: 200px;
  display: inline-block;
  overflow: hidden;
  background: none;
}

.circkle__loader {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0;
}

.circkle__loader div {
  box-sizing: content-box;
}

@keyframes circkleLoader {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes rightMovePre {
  0% {
    transform: scale(1.2);
  }
  55% {
    transform: translateX(-0);
  }
  95% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(100%);
  }
}

@keyframes leftMovePre {
  0% {
    transform: scale(1.2);
  }
  55% {
    transform: translateX(0);
  }
  95% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

@keyframes leftMoveBlock {
  55% {
    transform: translateX(0);
  }
  95% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

@keyframes rightMoveBlock {
  55% {
    transform: translateX(0);
  }
  95% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(100%);
  }
}

@media (max-width: 1100px) {
  .preloader__line {
    width: 84%;
  }
  .textPre {
    font-size: 6em;
  }
  .an {
    width: 100px;
  }
  .block1,
  .block2 {
    width: calc(50% - 50px);
  }
  .block1 svg {
    right: -93px;
  }
  .block2 svg {
    left: -105px;
  }
}

@media (max-width: 990px) {
  .textPre {
    font-size: 3.8em;
  }
  .an {
    width: 76px;
  }
  .block1,
  .block2 {
    width: calc(50% - 38px);
  }
  .block1 svg {
    right: -76px;
  }
  .block2 svg {
    left: -76px;
  }
}

@media (max-width: 772px) {
  .textPre {
    font-size: 3em;
    letter-spacing: 20px;
  }
  .an {
    width: 50px;
  }
  .block1,
  .block2 {
    width: calc(50% - 25px);
  }
  .block1 svg {
    right: -50px;
  }
  .block2 svg {
    left: -50px;
  }
}

@media (max-width: 663px) {
  .textPre {
    font-size: 2em;
    letter-spacing: 15px;
  }
}

@media (max-width: 557px) {
  .textPre {
    letter-spacing: 10px;
    font-size: 1.3em;
  }
}
