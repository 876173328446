@keyframes droidSmall {
  0% {
    transform: translateY(0%);
  }

  50% {
    transform: translateY(2%);
  }

  100% {
    transform: translateY(0%);
  }
}

@keyframes round_1 {
  0% {
    transform: matrix(0.8, 0, 0.25, 1, 1, 0);
  }

  50% {
    transform: matrix(1, 0, 0, 1, 0, 0);
  }

  100% {
    transform: matrix(0.8, 0, 0.25, 1, 1, 0);
  }
}

@keyframes pulsing {
  0% {
    transform: scale(0.8);
  }

  50% {
    transform: scale(1);
  }

  100% {
    transform: scale(0.8);
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.3);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes floating {
  0% {
    transform: translateY(0%);
  }

  50% {
    transform: translateY(1%);
  }

  100% {
    transform: translateY(0%);
  }
}

@keyframes slideLeft {
  0% {
    transform: translateX(150%);
  }

  50% {
    transform: translateX(-8%);
  }

  65% {
    transform: translateX(4%);
  }

  80% {
    transform: translateX(-4%);
  }

  95% {
    transform: translateX(2%);
  }

  100% {
    transform: translateX(0%);
  }
}

@keyframes slideRight {
  0% {
    transform: translateX(150%);
  }

  65% {
    transform: translateX(4%);
  }

  100% {
    transform: translateX(0%);
  }
}

@keyframes fadein {
  30% {
    opacity: 0;
  }

  70% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}

@keyframes dash {
  to {
    stroke-dashoffset: 100;
  }
}

@keyframes viewDemo_line {
  0% {
    transform: translateX(150%);
  }

  65% {
    transform: translateX(4%);
  }

  100% {
    transform: translateX(0%);
  }
}
