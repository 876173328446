@import "vars";

.title {
    font-family: 'Proxima Nova', serif;
    font-weight: 700;
    font-size: 16px;
    line-height: 26px;
    text-transform: uppercase;
    color: rgba(255, 255, 255, 0.9);
    margin-bottom: $list-title-margin;
}