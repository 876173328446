.logo {
  margin-left: 40px;
  width: auto;
  z-index: 11;
  filter: drop-shadow(0 0 1px #fff);
}

.logo svg {
  display: flex;
  max-width: 208px;
  height: auto;
  width: 100%;
}

@media (max-width: 560px) {
  .logo {
    margin-left: 20px;
  }
}
