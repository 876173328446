@import "vars";

.list {
    list-style: none;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: auto;
    gap: $list-title-margin;

    @media (max-width:1140px) {
        grid-template-columns: repeat(3, 1fr);
    }
    @media (max-width:800px) {
        grid-template-columns: 1fr;
    }

    &__item {
        display: flex;

        &:last-child {

            a {
                font-family: "Proxima Nova", serif;
                font-weight: 600;
                font-size: 18px;
                line-height: 24px;
                color: #EE7208;
                text-decoration: none;
                display: flex;
                align-items: center;

                img {
                    display: flex;
                    margin-right: 12px;
                }
            }
        }

        &:nth-child(n+4) {
            @media (max-width:1140px) {
                align-items: center;
            }
        }
    }

    &__icon {
        margin-right: $list-title-margin;
        height: fit-content;
        border-radius: 50%;
        overflow: hidden;
        box-shadow: 0 0 3px  #fff;
        width: 18px;
        height: 18px;
        position: relative;
        img{
            display: flex;
            object-fit: cover;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
        }
    }

    &__address {
        font-family: "Proxima Nova", serif;
        font-weight: 600;
        font-size: 18px;
        line-height: 15px;
        color: rgba(255, 255, 255, 0.8);

    }

    &__phone {
        font-family: "Proxima Nova", serif;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: rgba(255, 255, 255, 1);
        margin-top: calc($list-title-margin / 2);
    }
}