@import "vars";

.container{
    max-width: 1440px;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    padding: 0 $container-padding;

    @media (max-width: 1024px) {
        padding: 0 calc($container-padding / 2);
    }

    @media (max-width: 768px) {
        padding: 0 calc($container-padding / 4);
    }
}