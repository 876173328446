@import "vars";

.list {
    list-style: none;

    &__item {

        &:not(:last-child){
            margin-bottom: calc($list-title-margin / 2);
        }

        a {
            font-family: "Proxima Nova", serif;
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            color: rgba(255, 255, 255, 0.8);
        }
    }
}