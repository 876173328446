.topLineFixed {
  position: fixed !important;
}

.topLine {
  width: 100%;
  position: fixed;
  top: 40px;
  z-index: 997;
  height: 80px;
  align-items: center;
  transition: background-color 0.5s, box-shadow 0.5s;
}
.active.topLine {
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
}

@media (max-width: 48em) {
  .topLine {
    align-items: center;
    top: 70px;
  }
}
