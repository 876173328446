.wrapper {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    max-width: 375px;
    width: 100%;
    z-index: 10;
    transform: translateX(100%);

    &::before {
        content: "";
        display: block;
        width: 100%;
        height: 80px;
        background: linear-gradient(#3D40D5 0%, #3D40D5 80%, transparent 150%);
        box-shadow: 0 16px 16px -8px rgba(#3D40D5, 0.9);
        z-index: 1;
        top: 0;
        right: 0;
        position: absolute;
    }
}

.backdrop {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: rgba(#fff, 0.5);
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 9;
    opacity: 0;
    visibility: hidden;
}

.menu {
    overflow: auto;
    min-height: 100vh;
    height: 100%;
    padding: 85px 40px;
    width: 100%;
    background-color: #3D40D5;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.link {
    white-space: nowrap;
    font-size: clamp(2rem, 6vw, 3rem);
    color: #fff;
    width: fit-content;
    position: relative;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);


    &:last-of-type {
        margin-bottom: 40px;
    }

    &::after {
        content: "";
        display: block;
        height: 3px;
        background-color: #fff;
        left: 0;
        bottom: 0;
        width: 0;
        position: absolute;
        transition: width 0.3s;
    }

    &:hover, &_active {
        &::after {
            width: 100%;
        }
    }
}

.burger {
    position: relative;
    z-index: 11;
    background-color: transparent;
    cursor: pointer;
    outline: none;
    border: none;
    margin-right: 40px;
    margin-left: 16px;
    display: none;

    @media (max-width:56.25em) {
        display: flex;
    }


    @media (max-width: 35em) {
        margin-right: 20px;
    }
}

.robot {
    display: flex;
    margin-top: auto;
    margin-left: auto;
    flex: 0 0 auto;
}

.hidden {
    overflow: hidden;
}