.home {
  height: 100vh;
}

.home .content {
  height: 100%;
}

.home .content_left {
  padding-top: 110px;
  justify-content: center;
  background: #fff;
}

.home .iso {
  background: url(./assets/img/Home/iso-min.png) no-repeat;
  width: 76px;
  height: 51px;
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
}

.home .button-parent {
  margin-bottom: 30px;
  margin-top: 30px;
}

.home .content_right {
  background: #eaeffe url("./assets/img/Home/bg.png") no-repeat;
  background-size: cover;
}

.home .topLine {
  position: fixed;
  z-index: 11;
}

.home .hamburger {
  margin-right: 40px;
}

.home .illustrationWr {
  position: relative;
  overflow: hidden;
}

.menuScreen {
  position: relative;
}

.home .illustration {
  position: relative;
  right: 0;
}

.home .litleDroid {
  margin-bottom: 10px;
}

.home .litleDroid {
  animation-name: floating;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}

.home h1 {
  z-index: 10;
  padding: 0px 40px 0px 80px;
  font-weight: 600;
  font-size: 120px;
  line-height: 1.12;
  color: #141416;
  word-spacing: 9999999px;
}

.slideLeft {
  position: relative;
  z-index: 10;
  animation-name: slideLeft;
  animation-duration: 2s;
  animation-timing-function: ease-in-out;
  visibility: visible !important;
}

.slideRight {
  position: relative;
  z-index: 9;
  animation-name: slideRight;
  animation-duration: 3s;
  animation-timing-function: ease-out;
  visibility: visible !important;
}

.home .illustration {
  opacity: 0;
  animation: 4s ease 0s normal forwards 1 fadein;
}

.home .path {
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation: dash 5s linear forwards;
}

.home .viewDemo_line {
  animation-name: slideRight;
  animation-duration: 3s;
  animation-timing-function: ease-out;
  visibility: visible !important;
}

.viewDemo {
  margin: 30px 40px;
  margin-left: 80px;
  align-items: flex-start;
  flex-flow: column;
}

.home .viewDemo_buttons {
  display: flex;
  margin-top: 20px;
  justify-content: space-between;
  width: 100%;
  max-width: 360px;
}

.home .viewDemo_btn {
  width: 120px;
  height: 116px;
}

.viewDemo_text {
  font-family: "Proxima Nova", serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 1.6;
  width: 80%;
  color: #a8acb9;
}

.home .subscription {
  display: flex;
  margin: 10px 40px;
  flex-wrap: wrap;
}

.home .subscription_body {
  width: 50%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.home .subscription_body input {
  margin: 0;
  width: 240px;
  margin-right: 10px;
}

.home .subscription_title {
  font-family: "Proxima Nova", serif;
  font-weight: bold;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-size: 10px;
  line-height: 17px;
  color: #3d40d5;
}

.home .bottomLine {
  position: absolute;
  bottom: 100px;
  height: 14px;
  width: 100%;
  padding: 0 40px;
  z-index: 10;
}

.home .bottomLine a {
  font-family: "Proxima Nova", serif;
  font-size: 14px;
  line-height: 26px;
  color: #a8acb9;
  letter-spacing: 0.3px;
}

._no-scroll {
  overflow: hidden;
}

.home .content_block {
  width: 100%;
  height: 100%;
  max-width: 1280px;
  max-height: 720px;
}

.home .content_block iframe {
  background: transparent;
  border: 0;
  display: block;
  margin: 0;
  overflow: hidden;
  padding: 0;
  width: 100%;
  height: 100%;
}

.home-image {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 40px;
}

@media (max-width: 768px) {
  .home-image {
    margin-bottom: 40px;
  }
}

.home-image svg {
  max-width: 100%;
  height: auto;
  display: flex;
}

.promo-icon {
  transition: 0.5s;
}

.icons-hidden {
  opacity: 0;
}

@media (max-width: 1440px) {
  .home h1 {
    font-size: 96px;
  }
}

@media (max-width: 1200px) {
  .home h1 {
    font-size: 80px;
  }

  .viewDemo_text {
    width: 80%;
  }

  .home .illustration {
    width: 80%;
  }

  .viewDemo .button-parent {
    margin-bottom: 30px;
  }
}

@media (max-width: 1024px) {
  .viewDemo {
    margin: 30px 40px;
  }

  .home h1 {
    padding-left: 40px;
    line-height: 1.2;
  }

  .home {
    height: auto;
  }

  .viewDemo .button-parent {
    margin-bottom: 0;
  }

  .viewDemo_text {
    margin-top: 20px;
  }

  .viewDemo .button-parent {
    margin-bottom: 35px;
  }
}

@media (max-width: 960px) {
  .slick-slider {
    height: auto;
  }

  .home h1 {
    padding-left: 40px;
    font-size: 63px;
    padding-right: 0;
    line-height: 1;
  }

  .viewDemo_text {
    margin-top: 35px;
  }
}

@media only screen and (max-width: 1024px) and (max-height: 500px) {
  .home {
    height: auto;
  }
}

@media only screen and (max-height: 1100px) and (max-width: 1400px) {
  .home h1 {
    font-size: 72px;
  }
}

@media (max-width: 768px) {
  .home {
    height: auto;
  }

  .home .content {
    flex-flow: column-reverse;
    height: auto;
  }

  .home .content_left {
    padding-top: 0;
  }

  .home h1 {
    font-size: 53px;
    margin-top: -30px;
  }

  .viewDemo {
    margin-bottom: 0;
  }

  .viewDemo_text {
    margin-top: 0;
    width: 100%;
  }

  .home .slideRight {
    width: 100%;
    padding-top: 50px;
  }

  .Items_wrapper {
    flex-flow: column nowrap;
  }

  .Items_main_footer {
    margin: 0 40px 40px;
  }

  .slideRight.Item_page__svg {
    padding-top: 100px;
    padding-bottom: 50px;
  }

  .button-parent {
    width: 192px;
  }
}

@media (max-width: 560px) {
  .home h1 {
    font-size: 40px;
    margin-top: -40px;
    line-height: 1.2;
    padding-left: 20px;
    padding-right: 20px;
  }

  .viewDemo {
    margin-left: 20px;
    margin-right: 20px;
  }
}

@media (max-width: 480px) {
  .home h1 {
    font-size: 40px;
    line-height: 48px;
    margin-top: -33px;
  }
}

@media only screen and (max-height: 600px) {
  .home {
    height: auto;
  }
}
