@import "vars";

.footer {
    width: 100%;
    background-color: #051643;
    padding-top: 40px;
    padding-bottom: 5px;

    &__contacts {
        @media (max-width:1140px) {
            grid-column: span 3;
        }

        @media (max-width:800px) {
            grid-column: auto;
            grid-column-start: 2;
            grid-column-end: 3;
            grid-row-start: 1;
            grid-row-end: 5;
        }
        @media (max-width:520px) {
            grid-column-start: auto;
            grid-column-end: auto;
            grid-row-start: auto;
            grid-row-end: auto;
        }
    }

    &__top {
        padding-top: 10px;
        padding-bottom: 20px;
        position: relative;

        &::after {
            content: "";
            height: 1px;
            max-width: 500px;
            width: 100%;
            background-color: rgba(255, 255, 255, 0.5);
            position: absolute;
            left: 0;
            bottom: 0;

            @media (max-width:840px) {
                max-width: 100%;
            }
        }
    }

    &__logo {
        max-width: 345px;

        svg {
            display: flex;
            max-width: 345px;
            height: auto;
            width: 100%;
          }
    }

    &__center {
        padding-top: 30px;
        padding-bottom: 40px;
        position: relative;
        display: grid;
        grid-template-columns: minmax(max-content, 280px) minmax(max-content, 280px) minmax(max-content, 280px) minmax(max-content, auto);
        gap: $list-title-margin;

        @media (max-width:1140px) {
            grid-template-columns: 1fr 1fr 1fr;
            row-gap: $list-title-margin*2;
        }

        @media (max-width:800px) {
            grid-template-columns: 1fr 1fr;
        }

        @media (max-width:520px) {
            grid-template-columns: 1fr;
        }

        &::after {
            content: "";
            height: 1px;
            width: 100%;
            background-color:  rgba(255, 255, 255, 0.5);
            ;
            position: absolute;
            left: 0;
            bottom: 0;
        }
    }
    &__epic{
        grid-template-columns: minmax(max-content, auto) minmax(max-content, auto) minmax(max-content, 170px);
        padding-top: 30px;
        padding-bottom: 40px;
        position: relative;
        display: grid;
        gap: $list-title-margin;

        @media (max-width:1140px) {
            grid-template-columns: 1fr 1fr;
            row-gap: $list-title-margin*2;
        }

        @media (max-width:520px) {
            grid-template-columns: 1fr;
        }

        &::after {
            content: "";
            height: 1px;
            width: 100%;
            background-color: rgba(255, 255, 255, 0.5);
            position: absolute;
            left: 0;
            bottom: 0;
        }
    }
    &__bottom {
        padding-top: 30px;
        padding-bottom: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 16px;
        flex-wrap: wrap;

        @media (max-width:680px) {
            flex-direction: column;
            gap: $list-title-margin;
        }
    }

    &__links {
        a {
            font-family: 'Proxima Nova', serif;
            font-weight: 700;
            font-size: 16px;
            line-height: 26px;
            text-transform: uppercase;
            color: rgba(255, 255, 255, 0.5);

            &:not(:last-child) {
                margin-right: 56px;
            }
        }

        @media (max-width:680px) {
            order: 3;
        }
    }

    &__sci {
        margin-left: auto;

        @media (max-width:680px) {
            margin-left: 0;
            order: 2;
        }
    }

    &__widget {
        @media (max-width:680px) {
            order: 1;
        }
    }

    &__copyright {
        text-align: center;
        font-family: "Proxima Nova", sans-serif;
        font-weight: normal;
        font-size: 13px;
        line-height: 16px;
        color: rgba(255, 255, 255, 0.5);
    }
}