@import "vars", "reset";

#engenious {
    width: 100%;
    height: 100%;
    position: relative;
  }
  
  img {
    max-width: 100%;
    width: auto;
    height: auto;
  }

  footer .button-item{
    background-color: #fff;
  }