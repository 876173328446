.main{
    position: relative;
    min-height: 100vh;
    width: 100%;
    display: grid;
    overflow: hidden;
}

.headerNotice {
    position: fixed;
    top: 0;
    z-index: 999;
    width: 100%;
    height: 40px;
    display: flex;
    padding-left: 35px;
    align-items: center;
    color: white;
    flex-wrap: wrap;
    background: conic-gradient(from 219deg at 87.4% 50%, #021B65 0deg, #FF7B01 360deg), #FFF;
}

.headerNoticeLeftPart {
    display: flex;
    align-items: center;
}

.headerLogo {
    padding-right: 4px;
}

.monosoftLogo {
    padding: 0 4px;
}

.linkToMonosoft {
    color: white;
    cursor: pointer;
    text-decoration: underline;
    padding-left: 4px;
    font-size: 16px;
    line-height: 170%;
}

@media (max-width: 48em) {
    .headerNotice {
        flex-direction: column;
        height: 70px;
        justify-content: center;
        align-items: flex-start;
        padding-left: 16px;
    }

    .linkToMonosoft {
        font-size: 14px;
        line-height: 170%;
        padding-left: 0;
    }
}
