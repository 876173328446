html,
body {
  height: 100%;
}

.container {
  max-width: 1440px;
  width: 100%;
  padding: 0 40px;
  margin: 0 auto;
}

@media (max-width: 560px) {
  .container {
    padding: 0 20px;
  }
}

li {
  list-style-type: none;
}

a {
  text-decoration: none;
}

body {
  font-family: "Proxima Nova", serif;
  font-weight: 400;
}

._no-scroll,
.menu-open {
  overflow: hidden;
}

.wr {
  max-width: 100%;
  height: 100vh;
  overflow: hidden;
}

/* ===------ grid ------=== */

.col {
  display: flex;
  flex-flow: column nowrap;
}

.row {
  display: flex;
  flex-flow: row nowrap;
}

.wrap {
  flex-wrap: wrap;
}

.nowrap {
  flex-wrap: nowrap;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.jc {
  justify-content: center;
}

.ac {
  align-items: center;
}

.jsb {
  justify-content: space-between;
}

.jsa {
  justify-content: space-around;
}

.jfs {
  justify-content: flex-start;
}

.jfe {
  justify-content: flex-end;
}

.afs {
  align-items: flex-start;
}

.afe {
  align-items: flex-end;
}

.flex {
  flex: 1;
}

/* ===------ grid ------=== */

/* ===------ form ------=== */

.field__input {
  background-color: transparent;
  border-radius: 0;
  border: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  font-family: inherit;
  font-size: 1em;
}

.field__input:focus::-webkit-input-placeholder {
  color: transparent;
}

.field__input:focus::-moz-placeholder {
  color: #767676;
  opacity: 1;
}

.a-field {
  display: inline-block;
}

.a-field__input {
  display: block;
  box-sizing: border-box;
  width: 100%;
  color: #141416;
  font-size: 14px;
}

.a-field__input:focus {
  outline: none;
}

.a-field {
  position: relative;
  box-sizing: border-box;
  font-family: "Proxima Nova", serif;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  margin-bottom: 7px;
  color: #c5c9d4;
}

.a-field__input {
  height: 40px;
  margin-top: 32px;
  border-bottom: 2px solid #c6c8d4;
}

.a-field__input::-webkit-input-placeholder {
  opacity: 0;
  transition: opacity 0.2s ease-out;
}

.a-field__input::-moz-placeholder {
  opacity: 0;
  transition: opacity 0.2s ease-out;
}

.a-field__input:not(:placeholder-shown) ~ .a-field__label-wrap .a-field__label {
  opacity: 1;
  bottom: 37px;
  font-size: 9px;
}

.a-field__input:focus::-webkit-input-placeholder {
  opacity: 1;
  transition-delay: 0.2s;
}

.a-field__input:focus::-moz-placeholder {
  opacity: 1;
  transition-delay: 0.2s;
}

.a-field__label-wrap {
  box-sizing: border-box;
  width: 100%;
  height: 40px;
  pointer-events: none;
  cursor: text;
  position: absolute;
  bottom: 0;
  left: 0;
}

.a-field__label {
  position: absolute;
  bottom: calc(50% - 0.5em);
  line-height: 1;
  font-size: 14px;
  letter-spacing: 2px;
  pointer-events: none;
  transition: 0.2s;
  will-change: bottom, opacity;
}

.a-field__input:focus ~ .a-field__label-wrap .a-field__label {
  opacity: 1;
  bottom: 37px;
  font-size: 9px;
}

.a-field_a1 .a-field__input {
  transition: border-color 0.2s ease-out;
  will-change: border-color;
}

.a-field_a1 .a-field__input:focus {
  border-color: #4e10d5;
}

.form__field_last {
  margin-bottom: 40px;
}

.a-field_error .a-field__input {
  border-color: #ff5d5d;
}

.a-field_error:before {
  content: "!";
  position: absolute;
  right: 0;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  color: #fff;
  background: #ff5d5d;
  display: flex;
  justify-content: center;
  align-items: center;
}

.page404 {
  height: 100vh;
  justify-content: center;
  align-items: center;
  display: flex;
  color: #4e10d5;
  font-weight: bold;
}

/* TODO */

.see-more-btn {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 1.6;
  width: auto;
  text-align: center;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #141416;
  position: relative;
  align-self: flex-start;
  justify-self: flex-start;
}

.see-more-btn:before {
  content: "";
  width: 100%;
  height: 2px;
  position: absolute;
  bottom: -3px;
  display: block;
  background: #141416;
  left: 0px;
}

.seo-header {
  height: 0;
  color: transparent;
}

@keyframes slideBottom {
  0% {
    transform: translateY(100%);
  }

  100% {
    transform: translateY(0%);
  }
}

@media (max-width: 1024px) {
  .wr {
    height: auto;
  }
}

@media (max-width: 768px) {
  .wr {
    height: auto;
  }
}

@keyframes leftMove {
  0% {
    left: 100%;
  }

  25% {
    left: 15%;
  }

  50% {
    left: 15%;
  }

  75% {
    left: 15%;
  }

  95% {
    left: 100%;
  }

  100% {
    left: 100%;
  }
}

@keyframes rightMove {
  0% {
    right: 100%;
  }

  25% {
    right: 15%;
  }

  50% {
    right: 15%;
  }

  75% {
    right: 15%;
  }

  100% {
    right: 100%;
  }
}

.page-404 {
  display: flex;
  min-height: 100vh;
  padding-top: 98px;
  padding-bottom: 40px;
}

.page-404 .button-parent {
  margin: 0;
}

.page-404__image {
  margin-bottom: 32px;
}

.page-404__body {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.page-404__text {
  font-size: 24px;
  line-height: 38px;
  text-align: center;
  letter-spacing: 2px;
  color: #141416;
  margin-bottom: 32px;
}

.pause {
  -webkit-animation-play-state: paused !important;
  -moz-animation-play-state: paused !important;
  -o-animation-play-state: paused !important;
  animation-play-state: paused !important;
}

pre code {
  white-space: pre-wrap;
  word-wrap: break-word;
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  width: 250px;
}

.preloader-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (min-width: 500px) {
  pre code {
    width: 430px;
  }
}

@media (max-width: 1024px) {
  .arrowDown {
    display: none;
  }
}

.clutch_hidden {
  transform: scale(0);
}
