@import "../../scss/vars";

.nav {
    margin-left: 24px;
    margin-right: 40px;

    @media (max-width:56.25em) {
        display: none;
    }
}

.list {
    display: flex;
}

.dropdown {
    position: relative;

    &__button {
        border: none;
        outline: none;
        background-color: transparent;
        font-family: inherit;
        font-weight: bold;
        font-size: 1rem;
        line-height: 1.1875;
        display: flex;
        align-items: center;
        color: #EE7208;
        padding: 10px;
        text-transform: capitalize;
        cursor: pointer;
        gap: 6px;
        letter-spacing: 1px;

        svg {
            display: flex;
           
        }
    }

    &__list {
        position: absolute;
        top: 100%;
        right: 0;
        min-width: 100%;
        background: rgba(255, 255, 255, 0.6);
        box-shadow: inset 0 0 2px rgba($color: $accent, $alpha: 0.5);
        backdrop-filter: blur(10px);
        -webkit-backdrop-filter: blur(10px);
        border-radius: 8px;
        overflow: hidden;
        clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
    }

    &__item {
        &:not(:last-child) {
            border-bottom: 1px solid rgba($color: #000, $alpha: 0.1);
        }
    }

    &__link {
        padding: 10px 18px;
        display: flex;
        font-family: inherit;
        font-weight: bold;
        font-size: 1rem;
        line-height: 1.1875;
        color: #EE7208;
        position: relative;
        transition: 0.3s;
        align-items: center;
        white-space: nowrap;



        &:hover,
        &:global(.active) {
            background-color: rgba($color: #EE7208, $alpha: 1);
            color: #fff;
        }
    }
}